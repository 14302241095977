/* You can add global styles to this file, and also import other style files */
app-main .side-menu-container,
app-consumer-main .side-menu-container {
  max-width: 250px;
}

app-main .mat-drawer,
app-consumer-main .mat-drawer {
  min-width: 250px !important;
}

swiper > .swiper.s-wrapper {
  width: 100%;
  height: auto !important;
}

.swiper-button-next,
.swiper-button-prev {
  height: 24px !important;
}
